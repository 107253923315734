<template>
    <div class="bg h-100" style="overflow-y: auto" infinite-wrapper>
        <div class="container mt-2">
            <div class="row d-flex justify-content-start" v-for="(row, i) in renderableItems" v-bind:key="i">
                <div class="" v-for="item in row" v-bind:key="item.id">
                    <Item class="mt-4 mb-2 mx-1" :item="item" :purchasable="true"></Item>
                </div>
            </div>
            <infinite-loading v-if="!errorLoading" @infinite="infiniteHandler" ref="InfiniteLoading" force-use-infinite-wrapper></infinite-loading>

            <div v-if="errorLoading">Error loading</div>
        </div>
    </div>
</template>

<script>
import * as mock from '@/mock.js';
import api from "../api";
import parseItems from "../utils/parseItems";
import { mapGetters } from "vuex";

export default {
    props: [ ],
    data() {
        return {
            items: [],
            windowWidth: 0,
            appId: this.$route.meta.appId,
            continuationToken: null,
            errorLoading: false,
            discount: "",
        }
    },
    components: {
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        console.log("Getting new items")
        this.onResize();
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed: {
        ...mapGetters({
            filters: "filters/filters",
        }),
        filteredItems() {
            return this.items.filter(item => {
                let seenCommodities = {};

                if (seenCommodities[item.market_hash_name]) {
                    seenCommodities[item.market_hash_name].quantity++;
                    return false;
                }
                
                if (item.type == "COMMODITY") {
                    seenCommodities[item.market_hash_name] = item;
                    item.quantity = 1;
                }

                return true;
            });
        },
        renderableItems() {
            let toRender = this.filteredItems;

            const itemsPerRow = this.windowWidth >= 1400 ? 5 : 4;
            // Do the 'binning' into groups of 4
            return toRender.reduce((col, item, i) => {
                if (i % itemsPerRow === 0)
                    col.push([]); // Add a new row
                
                col[col.length - 1].push(item); // push item to the current row
                return col;
            }, []);
                
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },
        async infiniteHandler($state) {
            console.log("Infinite handler hit! Loading more");

            let isMoreItems = await this.loadMore();
            if (isMoreItems)
                $state.loaded();
            else
                $state.complete();

        },
        async loadMore() {
            // TODO: for some reason the same call is getting executed twice
            console.log("Loading more")
            let appId = parseInt(this.appId) || 730;
            let continuationToken = this.continuationToken;
            let maxItems = 10;

            console.log(`Attempting to load more for ${appId}`);
            let filters = {
                appId,
                maxItems,
                state: "LISTED",
            };
            for (let filterKey of Object.keys(this.filters)) {
                if (this.filters[filterKey]) filters[filterKey] = this.filters[filterKey];
            }

            // Try to use the newer continuation token, but fall back to the skip legacy if needed.
            if (continuationToken) {
                console.log("Getting more listings the new way")
                filters.continuationToken = continuationToken
            } else {
                console.log("Getting more listings the old way")
                filters.skip = this.items.length;
            }

            try {
                let discount = this.discount;
                let maxItems = 10;
                let appId = 730;

                let response = await api.getRecommendedItems({
                    appId,
                    discount,
                    maxItems,
                });

                this.discount = response.headers['discount'];

                this.items = this.items.concat(await parseItems(response.data));

                if (this.discount) return true;

                return false;
            } catch (e) {
                console.log("Unable to load items for grid view");
                console.log(e);
                this.errorLoading = true;
                return false;
            }
        },
    },

    watch: {
        $route(to, from) {
            this.appId = to.meta.appId;
            // this.items = this.items.splice(0, this.items.length);
            this.items = [];
            this.$refs.InfiniteLoading.stateChanger.reset();
        },
        filters: {
            deep: true,
            handler() {
                console.log("Change of filters");        
                this.items = []
                this.$refs.InfiniteLoading.stateChanger.reset(); 
                this.continuationToken = null
                // this.loadMore();
            }
        }
    }
}
</script>

<style lang="scss">
.rubik {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #D3D3D3;

}

.para-input {
  border-radius: 50px;
  width: 65px;
  max-width: 65px;
  display: inline;
}

.roboto {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #FFFFFF;

}

.roboto-sm {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #FFFFFF;

}
.bg {
    background: linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 100%), #FFFFFF;
}
</style>